<template>
  <section>
    <div class="m-1 mr-4 px-0 proposta">
      <breadcrumb :title="tituloTela" :actualPage="subTituloTela" />
    </div>
    <!-- <template v-if="!podeCriarProposta"> -->
    <ValidationObserver v-slot="{ invalid }">
      <template>
        <form-wizard
          ref="formWizard"
          color="rgba(var(--vs-warning), 1)"
          :title="null"
          :subtitle="null"
          :start-index="loaded ? ((proposta || {}).StatusId === 3 ? 3 : 0) : 0"
          finishButtonText="Submit"
          class="proposta"
        >
          <template slot="step" slot-scope="props">
            <wizard-step
              :tab="props.tab"
              :transition="props.transition"
              :key="props.tab.title"
              :checked="(proposta || {}).StatusId === 3"
              :disabled="(proposta || {}).StatusId === 3"
              :index="props.index"
            >
            </wizard-step>
          </template>

          <TagCanalCorretorMapfre
            v-if="podeExibirTagCanalCorretorMapfre"
            class="mb-6"
            :tipoCanalCorretora="tipoCanalCorretora"
            view="editar-proposta"
          />

          <tab-content title="Dados do tomador">
            <dados-tomador
              v-if="proposta.Status != 3 && proposta.PolicyHolder"
              :tomadorProposta="proposta.PolicyHolder"
            />
          </tab-content>

          <!-- tab 2 content -->
          <tab-content title="Dados do segurado">
            <dados-segurado
              v-if="proposta.Status != 3 && proposta.PolicyHolder"
              :cnpjTomador="proposta.PolicyHolder.CpfCnpj"
              :uniqueId="proposta.PolicyHolder.UniqueId"
              :idTomador="proposta.PolicyHolder.Id"
            />
          </tab-content>

          <!-- tab 3 content -->
          <tab-content title="Dados do risco">
            <dados-risco
              v-if="proposta.Status != 3 && proposta.Insured"
              :seguradoUniqueId="proposta.Insured.UniqueId"
              :seguradoId="proposta.Insured.Id"
              :idTomador="proposta.PolicyHolder.Id"
              :tomadorUniqueId="proposta.PolicyHolder.UniqueId"
              :tipoCanalCorretora="tipoCanalCorretora"
            />
          </tab-content>

          <!-- tab 4 content -->
          <tab-content title="Dados financeiros">
            <dados-financeiros
              :blockInputs="blockInputs"
              @saveProposal="salvarProposta()"
            />

            <h4 v-if="mostraCorretagem && deveExibirAgenciaMapfre" class="mb-5">Dados corretagem</h4>

            <vs-row v-if="mostraCorretagem && deveExibirAgenciaMapfre">
              <vs-col class="w-1/6">
                <ValidationProvider
                  name="Agência"
                  rules="required|min:4"
                  v-slot="{ errors }"
                  class="md:w-1/2 w-full md:pr-3"
                >
                  <p class="taxa-aplicada flex">
                    <b>Agência</b>
                    <vx-tooltip text="Agência do corretor." position="right">
                      <i class="material-icons pl-2">info</i>
                    </vx-tooltip>
                  </p>
                  <vs-input
                    class="w-full"
                    size="large"
                    v-mask="['####']"
                    :value="proposta.BrokerAgency"
                    @input="updateCorretagem({ BrokerAgency: $event })"
                  />
                  <span class="text-danger text-sm" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </vs-col>

              <vs-col class="w-1/6">
                <ValidationProvider
                  name="Matricula"
                  rules="required|min:2"
                  v-slot="{ errors }"
                  class="md:w-1/2 w-full md:pr-3"
                >
                  <p class="taxa-aplicada flex">
                    <b>Matricula</b>
                    <vx-tooltip text="Matricula do corretor." position="right">
                      <i class="material-icons pl-2">info</i>
                    </vx-tooltip>
                  </p>
                  <vs-input
                    class="w-full"
                    size="large"
                    v-mask="[
                      'A#',
                      'A##',
                      'A###',
                      'A####',
                      'A#####',
                      'A######',
                      'A#######'
                    ]"
                    @input="updateCorretagem({ BrokerRegistration: $event })"
                    :value="proposta.BrokerRegistration"
                  />
                  <span class="text-danger text-sm" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </tab-content>

          <template slot="footer" slot-scope="props">
            <div>
              <div class="wizard-footer-left">
                <vs-button
                  v-if="props.activeTabIndex > 0 && props.activeTabIndex < 3"
                  @click.native="props.prevTab()"
                  color="primary"
                >
                  <span class="font-semibold">Voltar</span>
                </vs-button>
              </div>

              <div class="wizard-footer-right">
                <vs-button
                  v-if="props.isLastStep && podeEmitirApolice"
                  @click="enviarEventoAppInsights('desistir'), $router.push({ name: 'propostas' })"
                  class="button-default"
                  type="filled"
                >
                  <span class="font-semibold">
                    Desistir
                  </span>
                </vs-button>
                <vs-button
                  v-if="props.isLastStep"
                  class="button-default"
                  @click.native="enviarEventoAppInsights('salvar-proposta'), salvarProposta()"
                  type="filled"
                >
                  <span class="font-semibold">
                    Salvar Proposta
                  </span>
                </vs-button>
                <vs-button
                  :class="{
                    'fix-button':
                      proposta.Status !== 0 && props.activeTabIndex === 2
                  }"
                  v-if="
                    !props.isLastStep &&
                      proposta.Status !== 0 &&
                      props.activeTabIndex != 2
                  "
                  @click.native="props.nextTab()"
                  color="primary"
                >
                  <span class="font-semibold">
                    Prosseguir
                  </span>
                </vs-button>

                <vs-button
                  color="primary"
                  v-if="props.isLastStep && podeSolicitarAjuste && 1 == 0"
                  @click="popupSolicitacao = true"
                  class="button-default"
                >
                  <span class=""
                    >Solicitação especial de ajuste na proposta</span
                  >
                </vs-button>

                <vs-button
                  v-if="props.isLastStep && podeEmitirApolice"
                  @click="imprimirMinuta()"
                  class="button-default"
                  type="filled"
                >
                  <span class="font-semibold">
                    Imprimir minuta
                  </span>
                </vs-button>
                <vs-button
                  v-if="props.isLastStep && podeEmitirApolice"
                  :disabled="invalid"
                  @click="emitirApolice()"
                  color="primary"
                >
                  <span class="font-semibold pl-2">
                    Emitir apólice
                  </span>
                </vs-button>

                <vs-button
                  v-if="podeFazerDownloadLaudoEmissao"
                  @click="downloadLaudoEmissao()"
                >
                  Download do laudo de emissão
                </vs-button>

                <vs-popup :active="popupSolicitacao" title="">
                  <h3 class="text-primary pb-3 font-semibold">
                    Solicitação especial de ajuste na proposta
                  </h3>
                  <p class="font-semibold py-2">Observação</p>
                  <vs-textarea v-model="textQuestionamento" height="150px" />
                  <vs-button
                    color="primary"
                    type="filled"
                    class="btn-popup px-12"
                    @click="sendQuestionario"
                    :disabled="textQuestionamento.length === 0"
                  >
                    <span class="font-semibold pr-2 pl-2">Enviar</span>
                  </vs-button>
                </vs-popup>

                <vs-popup
                  class="termos-aceite-modal"
                  title=""
                  :active="assinarNovoTermoDeAceiteProposta"
                >
                  <div class="vw-row">
                    <div class="vx-col w-full">
                      <h4>Termo e declaração</h4>
                      <small
                        ><b
                          >Leia os termos para a solicitação de emissão da
                          apólice</b
                        ></small
                      >
                    </div>
                  </div>
                  <div class="vw-row mt-3">
                    <div
                      class="vx-col w-full"
                      style="background-color:'#f2f2f2'"
                    >
                      <span class="text-justify" v-html="TermoAceite"></span>
                    </div>
                  </div>
                  <div class="vw-row mt-3">
                    <div class="vx-col w-full">
                      <vs-checkbox
                        class="mt-8 mb-4 mx-0"
                        v-model="aceitouTermosDeAceiteProposta"
                      >
                        Declaro que li e estou ciente das condições acima.
                      </vs-checkbox>
                    </div>
                  </div>
                  <div class="vw-row mt-3">
                    <div class="vx-col w-full text-right">
                      <vs-button
                        @click="cancelarEmissao()"
                        class="button-default mr-5"
                      >
                        Cancelar
                      </vs-button>
                      <vs-button
                        :disabled="!aceitouTermosDeAceiteProposta"
                        @click="validaDataVigencia()"
                        class="button-primary"
                      >
                        Emitir
                      </vs-button>
                    </div>
                  </div>
                </vs-popup>
                <vs-popup
                  class="validacao-data-vigente-modal"
                  title=""
                  :active="editaDataVigente"
                >
                  <div class="vw-row">
                    <div class="vx-col w-full">
                      <h4>Aviso</h4>
                      <small
                        ><b
                          >A data de vigência da proposta não pode ser
                          retroativa.</b
                        ></small
                      >
                    </div>
                  </div>
                  <div class="vw-row mt-3">
                    <div
                      class="vx-col w-full"
                      style="background-color:'#f2f2f2'"
                    >
                      <span> {{ mensagemRetornoData }}.</span>
                    </div>
                  </div>
                  <div class="vw-row mt-3">
                    <div class="vx-col w-full">
                      <span>Deseja alterar?</span>
                    </div>
                  </div>
                  <div class="vw-row mt-3">
                    <div class="vx-col w-full text-right">
                      <vs-button
                        @click="cancelarEdicaoDatas()"
                        class="button-default mr-5"
                      >
                        Cancelar
                      </vs-button>
                      <vs-button
                        @click="confirmarEmissao(true)"
                        class="button-primary"
                      >
                        Confirma
                      </vs-button>
                    </div>
                  </div>
                </vs-popup>

                <vs-popup
                  class="modal-explicativo-emissao-mapfre"
                  title=""
                  :active="explicativoEmissaoMapfre"
                  :button-close-hidden="true"
                  >
                  <div class="vw-row">
                    <div class="vx-col w-full text-center modal-explicativo-emissao-mapfre-content">
                      <h4>Atenção</h4>
                      <p>
                        Sua solicitação está sendo processada. Caso a Apólice/Boleto(s) ainda não esteja(m) disponível(eis) para download na próxima tela, vá para a área de consulta de propostas ou apólices e verifique se está(ão) disponível(eis). Você também receberá no seu e-mail a confirmação da emissão da apólice.
                      </p>
                    </div>
                  </div>

                  <div class="vw-row mt-3">
                    <div class="vx-col w-full text-center">
                      <vs-button
                        @click="prosseguirEmissaoMapfre()"
                        class="button-primary"
                      >
                        Prosseguir
                      </vs-button>
                    </div>
                  </div>
                </vs-popup>
              </div>
            </div>
          </template>
        </form-wizard>
      </template>
    </ValidationObserver>
  </section>
</template>
<script>
import DadosFinanceiros from "./components/emissao/DadosFinanceiros.vue";
import DadosTomador from "./components/criacao/DadosTomador.vue";
import DadosSegurado from "./components/criacao/DadosSegurado.vue";
import DadosRisco from "./components/criacao/DadosRisco.vue";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import axiosInstance from "../../axios";
import * as Enums from "../../utils/enums";
import { mask } from "vue-the-mask";
import environment from "@/environment";

import * as Permissoes from "@/utils/permissions.js";
import mapfreProvider from "@/providers/mapfre-provider.js";
import TagCanalCorretorMapfre from "@/components/tag-canal-corretor-mapfre/index.vue";

export default {
  components: {
    DadosFinanceiros,
    Breadcrumb,
    DadosTomador,
    DadosSegurado,
    DadosRisco,
    TagCanalCorretorMapfre
  },
  directives: { mask },
  data() {
    return {
      assinarNovoTermoDeAceiteProposta: false,
      aceitouTermosDeAceiteProposta: false,
      editaDataVigente: false,
      textQuestionamento: "",
      loaded: false,
      popupSolicitacao: false,
      popupEmitirApolice: false,
      urlDownloadLaudoEmissao: "",
      mensagemRetornoData: "",
      Mapfre: false,
      TermoAceite: "",
      explicativoEmissaoMapfre: false,
      permissao: [],
      tipoCanalCorretora: {},
      permissoesCorretorMapfre: [],
      isMapfre: false
    };
  },
  watch: {
    "$route.params.propostaUniqueId"(newId, oldId) {
      this.limparEstado();
      this.loadProposta()
        .then(() => {
          this.loaded = true;
          if (this.proposta.StatusId === 3)
            this.$refs.formWizard.changeTab(0, 3);
          else this.$refs.formWizard.changeTab(0, 0);
        })
        .catch(error => {
          return this.$router.push({ name: "propostas", params: {} });
        });
    }
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
    podeEmitirApolice: {
      get() {
        return (
          this.timeline.LastSituationId ===
            Enums.ProposalTimelineSituation.Emissao &&
          this.timeline.LastStatusId ===
            Enums.ProposalTimelineStatus.Corretor &&
          this.proposta.StatusId == Enums.ProposalStatus.AguardandoEmissao
        );
      }
    },
    mostraCorretagem: {
      get() {
        return environment.CompanyId.Mapfre == this.proposta.CompanyId;
      }
    },
    deveExibirAgenciaMapfre() {
      return this.permissao.includes("ExibirAgencia");
    },
    podeSolicitarAjuste: {
      get() {
        return (
          this.timeline.LastSituationId ===
            Enums.ProposalTimelineSituation.Emissao &&
          this.timeline.LastStatusId === Enums.ProposalTimelineStatus.Corretor
        );
      }
    },
    podeFazerDownloadLaudoEmissao: {
      get() {
        return this.proposta.StatusId == Enums.ProposalStatus.Emitida;
      }
    },
    timeline: {
      get() {
        return (this.proposta || {}).Timeline || {};
      }
    },
    tituloTela: {
      get() {
        if ((this.proposta || {}).StatusId === 1) return "Cotações";
        else return "Propostas";
      }
    },
    subTituloTela: {
      get() {
        //proposta.Status === 3 ? 'Emissão' : 'Editar Proposta'
        if ((this.proposta || {}).StatusId === 1) return "Editar Proposta";
        else if ((this.proposta || {}).StatusId === 3) return "Emissão";
        else return "Editar Proposta";
      }
    },
    blockInputs: {
      get() {
        return this.proposta.BlockChangesInProposal;
      }
    },
    podeExibirTagCanalCorretorMapfre() {
      return this.permissoesCorretorMapfre.includes("ExibirCanal");
    },
  },
  beforeMount() {
    this.limparEstado();
  },
  async mounted() {
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    let Mapfre = parseInt(environment.CompanyId.Mapfre);
    this.Mapfre = portalConfig.CompanyId == Mapfre ? true : false;
    //this.loadProposta()
    this.$onpoint.loading(() => {
      return this.validarCorretoraAtivaFn()
        .then(() => {
          this.loaded = true;
          if (this.proposta.StatusId === 3)
            this.$refs.formWizard.changeTab(0, 3);
          else this.$refs.formWizard.changeTab(0, 0);
        })
        .catch(error => {
          return this.$router.push({ name: "propostas", params: {} });
        });
    });
    await this.$onpoint.loading(async () => {
      await this.pegarPermissaoParaExibirAgenciaMapfre()
      await this.pegarPermissoesMapfre();
    });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "editar-proposta",
      url: window.location.href
    });
  },
  methods: {
    ...mapActions("proposta-module", [
      "loadPropostaByUniqueId",
      "sendProposalAdjustment",
      "limparEstado",
      "getValidateRetroactivityDays",
      "getUpdateFinancialData",
      "gerarMinuta"
    ]),
    ...mapMutations("proposta-module", ["updateCorretagem"]),
    ...mapActions("cadastro-corretora", ["validarCorretoraAtiva"]),
    validarCorretoraAtivaFn() {
      return this.validarCorretoraAtiva()
        .then(data => {
          return this.loadProposta();
        })
        .catch(err => {
          this.$onpoint.errorModal(err.response.data.Errors);
          this.$router.push("/redirect-to-home");
        });
    },
    async loadProposta() {
      return await this.$onpoint.loading(async () => {
        return await this.loadPropostaByUniqueId(this.$route.params.propostaUniqueId)
          .then(data => {
            this.buscarTermoAceite();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
    validaDadosProposta(dados) {
      let result = false;
      if (dados.ProposalId == null) {
        result = "Id da proposta";
      }
      if (dados.GracePeriod == null) {
        result = "Período de carência";
      }
      if (dados.InsurancePremium == null) {
        result = "Prêmio da modalidade principal";
      }
      if (dados.ComplementaryModality.Id != 0) {
        if (dados.InsurancePremiumComplementaryModality == null) {
          result = "Prêmio da modalidade complementar";
        }
      }
      if (dados.RiskRate == null || undefined) {
        result = "Taxa de risco";
      }
      if (dados.ComissionValue == null || undefined) {
        result = "Comissão";
      }
      if (dados.BlockChangesInProposal == null || undefined) {
        result = "Bloquear alterações de valores da Proposta";
      }
      if (dados.InsurancePremiumSetToZero == null || undefined) {
        result = "Parâmetro - InsurancePremiumSetToZero";
      }
      if (dados.EnableToBigRisk == null || undefined) {
        result = "Grande risco";
      }
      if (dados.EnableToMonitoring == null || undefined) {
        result = "Monitoramento";
      }
      if (dados.InsurancePremiumSetManually == null || undefined) {
        result = "Parâmetro - InsurancePremiumSetManually";
      }
      if (dados.EnableToSteppin == null || undefined) {
        result = "Retoma de objeto (Step-in)";
      }
      if (dados.ProposalContractualTerms.length <= 0) {
        result = "Objetos da proposta";
      }
      return result;
    },
    salvarProposta() {
      let payload =  JSON.parse(JSON.stringify(this.proposta));
      if (!payload.ProposalId) {
        payload.ProposalId = payload.Id;
      }
      if (!payload.Coverages) {
        payload.Coverages = JSON.parse(JSON.stringify( this.$store.state["proposta-module"].propostaFinancialData.Coverages))
        let array = []
        payload.Coverages.map(x => {
          array.push(
            {
              Value: x.CoveragePremium,
              CoverageId: x.CoverageId,
              CoveragePremium: x.CoveragePremium,
            }
          )
        });
        payload.Coverages = array
        console.log(array,    payload.Coverages)
      }
      if (!payload.ContractualTerms) {
        payload.ContractualTerms = payload.ProposalContractualTerms;
        payload.ContractualTerms.map(x => {
          x.ContractualTermId =  x.Id
        });
      }
      payload.InsurancePremiumPrincipalModality = this.$store.state["proposta-module"].propostaFinancialData.PrincipalModality
      payload.InsurancePremium = this.$store.state["proposta-module"].propostaFinancialData.InsurancePremium
      payload.InsurancePremiumComplementaryModality =  this.$store.state["proposta-module"].propostaFinancialData.ComplementryModality
      payload.ComissionPercentage = this.$store.state["proposta-module"].propostaFinancialData.ComissionPercentage
      payload.ComissionValue = this.$store.state["proposta-module"].propostaFinancialData.ComissionValue
      payload.RiskRate = this.$store.state["proposta-module"].propostaFinancialData.Tax

      let result = this.validaDadosProposta(payload);
      if (result) {
        this.$onpoint.errorModal("Dados inválidos: " + result);
        return;
      }
      this.$onpoint.loadingModal("Estamos salvando a proposta", () => {
        return axiosInstance
          .post("api/proposal/UpdateProposal", payload)
          .then(response => {
            this.$vs.notify({
              title: "Sucesso",
              text: "Proposta salva com sucesso!",
              color: "success"
            });
            setTimeout(() => {
              this.$router.push({
                name: "timeline-proposta",
                params: { propostaUniqueId: this.proposta.UniqueId }
              });
            }, 500);
          })
          .catch(errors => {
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },
    buscarTermoAceite() {
      return axiosInstance
        .get("api/CompanyAcceptanceTerm/GetLastCompanyAcceptanceTerm")
        .then(response => {
          this.TermoAceite = response.data.Response.AcceptanceTerm;
        });
    },
    emitirApolice() {
      this.enviarEventoAppInsights('abrir-emitir-apolice-modal')
      this.assinarNovoTermoDeAceiteProposta = true;
    },
    iniciarEmissaoApolice() {
      this.assinarNovoTermoDeAceiteProposta = true;
      this.aceitouTermosDeAceiteProposta = false;
    },
    cancelarEmissao() {
      this.enviarEventoAppInsights('fechar-emitir-apolice-modal')
      this.assinarNovoTermoDeAceiteProposta = false;
      this.aceitouTermosDeAceiteProposta = false;
    },
    cancelarEdicaoDatas() {
      this.assinarNovoTermoDeAceiteProposta = false;
      this.aceitouTermosDeAceiteProposta = false;
      this.editaDataVigente = false;
    },
    downloadLaudoEmissao() {
      window.open(this.urlDownloadLaudoEmissao, "_self");
    },

    async imprimirMinuta() {
      let payload =  JSON.parse(JSON.stringify(this.proposta));
      if (!payload.ProposalId) {
        payload.ProposalId = payload.Id;
      }
      if (!payload.Coverages) {
        payload.Coverages = JSON.parse(JSON.stringify( this.$store.state["proposta-module"].propostaFinancialData.Coverages))
        let array = []
        payload.Coverages.map(x => {
          array.push(
            {
              Value: x.CoveragePremium,
              CoverageId: x.CoverageId,
              CoveragePremium: x.CoveragePremium,
            }
          )
        });
        payload.Coverages = array
        console.log(array,    payload.Coverages)
      }
      if (!payload.ContractualTerms) {
        payload.ContractualTerms = payload.ProposalContractualTerms;
        payload.ContractualTerms.map(x => {
          x.ContractualTermId =  x.Id
        });
      }
      payload.InsurancePremiumPrincipalModality = this.$store.state["proposta-module"].propostaFinancialData.PrincipalModality
      payload.InsurancePremium = this.$store.state["proposta-module"].propostaFinancialData.InsurancePremium
      payload.InsurancePremiumComplementaryModality =  this.$store.state["proposta-module"].propostaFinancialData.ComplementryModality
      payload.ComissionPercentage = this.$store.state["proposta-module"].propostaFinancialData.ComissionPercentage
      payload.ComissionValue = this.$store.state["proposta-module"].propostaFinancialData.ComissionValue
      payload.RiskRate = this.$store.state["proposta-module"].propostaFinancialData.Tax

      let result = this.validaDadosProposta(payload);
      if (result) {
        this.$onpoint.errorModal("Dados inválidos: " + result);
        return;
      }
      this.enviarEventoAppInsights('imprimir-minuta')
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .post("api/Proposal/UpdateProposal", payload)
          .then(async () => {
            return await axiosInstance
              .get(
                `api/Proposal/GenerateContractDraft?ProposalUniqueId=${this.proposta.UniqueId}&IsSaved=false`
              )
              .then(response => {
                window.open(response.data.Response.UrlToDownload, "_self");
              })
              .catch(errors =>
                this.$onpoint.errorModal(errors.response.data.Errors)
              );
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    checkCoBrokers() {
      const totalCoBrokers =
        this.proposta.CoBrokers &&
        this.proposta.CoBrokers.reduce(
          (total, coBroker) => parseFloat(total) + parseFloat(coBroker.Rate),
          0
        );

      return totalCoBrokers === 100 || this.proposta.CoBrokers.length === 0
        ? true
        : false;
    },

    async validaDataVigencia() {
      this.assinarNovoTermoDeAceiteProposta = false;
      this.aceitouTermosDeAceiteProposta = false;

      this.enviarEventoAppInsights('emitir-apolice')
      await this.$onpoint.loadingModal("Estamos validando a apólice", async () => {
        return await this.getValidateRetroactivityDays(this.proposta.UniqueId)
          .then(res => {
            if (res.HasChangedDates) {
              this.mensagemRetornoData = res.Message;
              this.editaDataVigente = true;
            } else {
              setTimeout(() => {
                this.Mapfre ? this.confirmarcaoEmissaoMapfre(false) : this.confirmarEmissao(false);
              }, 500);
            }
          })
          .catch(errors => {
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },

    async confirmarEmissao(dateHasChanged) {
      this.assinarNovoTermoDeAceiteProposta = false;
      this.aceitouTermosDeAceiteProposta = false;
      this.editaDataVigente = false;

      if (!this.checkCoBrokers()) {
        this.$onpoint.errorModal(
          "A cocorretagem tem que somar 100% do valor da comissão"
        );
        return;
      }

      await this.$onpoint.loadingModal("Estamos emitindo a apólice", async () => {
        try {
          const endpoint = dateHasChanged
          ? "api/Proposal/UpdateRetroactivityDaysAndCreatePolicy"
          : "api/Proposal/CreatePolicy";

          const response = await axiosInstance.post(endpoint, this.proposta);

          this.$vs.notify({
            title: "Sucesso",
            text: "Solicitação de emissão de apólice realizada com sucesso!",
            color: "success"
          });

          if (!this.Mapfre) {
            setTimeout(() => {
              this.$router.push({
                name: "timeline-proposta",
                params: { propostaUniqueId: this.proposta.UniqueId }
              });
            }, 500);
          } else {                           
            this.exibirPopupExplicativoMapfre()
          }
        } catch (errors) {
          const errorMessage = errors.response ? errors.response.data.Errors : "Erro ao emitir a apólice";
          this.$onpoint.errorModal(errorMessage);
        }
      });
    },

    async confirmarcaoEmissaoMapfre(dateHasChanged) {
      await this.$onpoint.loadingModal("Estamos confirmando a emissão", async () => {
        try {
          const response = await axiosInstance.post("api/Proposal/CreatePolicyPrimeiraChamadaMapfre", this.proposta);

          if (!response.data.Response.PolicyNumber) {
            throw new Error("Houve um erro para gerar a apólice na Mapfre. Favor aguardar alguns instantes e clique emitir novamente");
          }

          await this.confirmarEmissao(dateHasChanged);
        } catch (errors) {
          const errorMessage = errors.response ? errors.response.data.Errors : "Erro ao confirmar emissão";
          this.$onpoint.errorModal(errorMessage);
        }
      })
    },

    async sendQuestionario() {
      await this.$onpoint.loading(async() => {
        return await this.sendProposalAdjustment({
          ProposalUniqueId: this.$route.params.propostaUniqueId,
          Description: this.textQuestionamento
        })
          .then(() => {
            this.popupSolicitacao = false;
            setTimeout(() => {
              this.$router.push({
                name: "timeline-proposta",
                params: {
                  propostaUniqueId: this.$route.params.propostaUniqueId
                }
              });
            }, 500);
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },

    exibirPopupExplicativoMapfre(){
      this.explicativoEmissaoMapfre = true;
    },

    prosseguirEmissaoMapfre(){
      this.explicativoEmissaoMapfre = false;
      setTimeout(() => {
        this.$router.push({
          name: "timeline-proposta",
          params: { propostaUniqueId: this.proposta.UniqueId }
        });
      }, 500);
    },

    async pegarPermissaoParaExibirAgenciaMapfre() {
      // Esse método tem relação com qual canal foi escolhido pela seguradora Mapfre para a corretora
      this.permissao = [];
      try {
        this.permissao = await mapfreProvider.exibirAgenciaParaCanalDaProposta();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async pegarPermissoesMapfre() {
      this.permissoesCorretorMapfre = []
      this.tipoCanalCorretora = {};
      try {
        this.permissoesCorretorMapfre = await mapfreProvider.obterPermissoesModulo(Permissoes.Modulo.PROPOSTA)
        this.tipoCanalCorretora = await mapfreProvider.buscarTipoCanalPorCorretora();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    enviarEventoAppInsights(action){
      this.$appInsights.trackEvent({ name: `${action}-proposta`, properties: { place: "proposta-passo-4", action: "button-click", slug: `${action}-proposta-passo-4` }});
    }
  }
};
</script>
<style lang="scss">
.termos-aceite-modal {
  z-index: 52900 !important;
}

.modal-explicativo-emissao-mapfre{
  z-index: 52900 !important;

  &-content{
    padding: 0 30px;

    h4 {
      font-size: 24px;
      margin-bottom: 30px;
    }

    p{
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}

.fix-button {
  position: relative !important;
  right: 398px !important;
}
.proposta {
  .wizard-tab-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .wizard-tab-content {
    background: white !important;
    margin: 2em 0 auto;
    padding: 37px !important;
  }

  .wizard-card-footer {
    background-color: white !important;
    min-height: 70px;
    padding: 0 37px !important;
  }

  .wizard-footer-left,
  .wizard-footer-right {
    padding: 0 0.5rem !important;
    .vs-button {
      padding: 0.75rem 20px !important;
    }
  }
  .wizard-footer-right {
    padding: 0 0.5rem !important;
    .vs-button {
      margin-left: 15px;
    }
  }
}
</style>

<style lang="scss" scoped>
h4 {
  color: rgba(var(--sv-dark), 1);

  i {
    color: rgba(var(--vs-warning), 1);
    display: block;
    font-size: 0.8em;
  }
}

.link {
  float: right;
}

.btn-popup {
  float: right;
}
</style>
